import data from './config.json'

let instance = null

class Config {

  static getMapping() {
    return data["mapping"]
  }

}
export default Config;
