import {Button} from './Button.js'
import Row from './Row.js'

let instance = null

class Controller {

  static instance() {
    if (instance) {
      return instance;
    } else {
      instance = new Controller()
      return instance
    }
  }

  constructor() {
    // callbacks
    this.onPreview = null
    this.onStop = null
    this.onPlayPlaylist = null
    this.size = [0, 0]
    this.rows = []
    this.buttons = []
    this.animations = []
    this.resetTimer = null
  }

  init(size) {
    this.size = size
    // make rows
    this.rows = [...Array(size[1])].map((_, i) => {
      var row = new Row(size[0], i)
      return row
    })
    // convenience array for accessing all buttons
    this.buttons = this.rows.reduce((acc, row) => {
      return acc.concat(row.buttons)
    }, [])
    // help button not bound to a row
    this.helpButton = new Button()
    this.buttons.push(this.helpButton)
  }

  // set the button state reference by its filename and reset the inactivity timeout
  setButtonPlaying(filename, time) {
    this.buttons.forEach((button, i) => {
      if(button.getFilename() === filename) {
        button.playing = true
        button.playingTime = time
      } else {
        button.playing = false
        button.playingTime = 0
      }
    })
    if(this.resetTimer) {
      clearTimeout(this.resetTimer)
    }
    this.resetTimer = setTimeout(() => {
      this.buttons.forEach((button, i) => {
        button.playing = false
        button.active = false
      })
    }, 10000)
  }

  // get button at coordinate
  getButton(row, col) {
    return ((this.rows[row] || {}).buttons || [])[col]
  }

  //
  onClick(row, col) {
    var button = this.getButton(row, col)
    if (button) {
      // if anything is playing stop it
      var playing = this.buttons.filter(b => b.playing)[0]
      if (playing) {
        if (this.onStop) {
          this.onStop()
        }
      }
      // toggle if off
      if(!button.active) {
        button.active = true
      }
      //play if not just paused
      if(playing != button) {
        this.onPreview(button.getFilename())
      }
    }
  }

  // on double click and hold
  onDoubleClick(row, col) {
    var button = this.getButton(row, col)
    if (button) {
      // if anything is playing stop it
      var playing = this.buttons.filter(b => b.playing)[0]
      if (playing) {
        if (this.onStop) {
          this.onStop()
        }
      }
      // toggle if on
      if(button.active) {
        button.active = false
      }
    }
  }

  //
  onRowButton(row) {
    console.log("row playing", this.rows[row].isPlaying())
    var button = this.rows[row].buttons.slice(-1)[0]
    // if anything is playing stop it
    var playing = this.buttons.filter(b => b.playing)[0]
    if (playing) {
      if (this.onStop) {
        this.onStop()
      }
    }
    //
    if(button != playing) {
      this.onPreview(button.getFilename())
    }
  }

  //
  onHelpButton() {
    // if anything is playing stop it
    var playing = this.buttons.filter(b => b.playing)[0]
    if (playing) {
      if (this.onStop) {
        this.onStop()
      }
    }
    //
    if(this.helpButton != playing) {
      this.onPreview(this.helpButton.getFilename())
    }
  }

  // return an array of css classes naming state and color for the
  getDisplay() {
    //
    var display = this.rows.map(row => {
      return row.buttons.map(button => {
        return button.getColor()
      })
    })
    display.push(this.helpButton.getColor())
    return display
  }

  //
  getRowButtonDisplay() {

  }

  //
  listFilenames() {
    return this.buttons.map((b, i) => b.getFilename())
  }

}


export default Controller
