import React from 'react'
import './IntroText.css'

const IntroText = props =>
  <div className="IntroText">
    <div className="IntroText-pic">
    </div>
    <h1>
      Voorbij je bubbel...
    </h1>
    <p>
      Erfgoed maken we samen. Iedereen heeft daarin een stem. Hier vertellen buurtbewoners van Amsterdam Zuidoost wat zij voelen bij (beladen) erfgoedobjecten. Luister naar de stemmen uit de buurt. Door wie word jij geraakt?
    </p>
  </div>

export default IntroText
