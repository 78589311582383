// mapping of person id to  color, move this to config file
const Colors = [
  "orange",
  "yellow",
  "green",
  "lightblue",
  "blue",
  "pink",
  "purple"
]


class Button {

  constructor() {
    this.pressedTime = 0 // time since it was pressed
    this.person = null
    this.active = false
    this.row = null
    this.col = null
    this.fragment = null
    this.playing = false
    this.animating = false
    this.playing_time = 0
  }

  // get position in row
  getPosition() {
    this.row.buttons.indexOf(this)
  }

  // get filename for this button
  getFilename() {
    if(!this.row) {
      return "help" //the help button is not bound to a row
    }
    if(!this.person) {
      return `${this.row.row+1}` //row intro button
    }
    return `${this.row.row+1}_${this.person}_${this.fragment}`
  }

  // get classes defining color based upon state
  getColor() {
    // row button or help button
    if(!this.person) {
      var classes = ""
      if(this.playing && (Math.floor(this.playingTime) % 2) == 1) {
        classes += "playing playing-0"
      } else if(this.playing) {
        classes += "playing playing-1"
      }
      return classes;
    }
    // pixel button
    var classes = Colors[this.person-1]
    if(this.playing) {
      classes += " playing"
      if((Math.floor(this.playingTime) % 2) == 1) {
        classes += " playing-0"
      } else {
        classes += " playing-1"
      }
    } else if(this.active) {
      classes += " on"
    } else {
      classes += " off"
    }
    return classes
  }

}

export {
   Button
}
