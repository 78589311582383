let instance = null

class Timer {

  static instance() {
    if(instance) {
      return instance;
    } else {
      instance = new Timer()
      return instance
    }
  }

  constructor() {
    this.timer = setInterval(() => this.tick(), 100)
    this.onTick = null
    this.cnt = 0
  }

  tick() {
    if(this.onTick) {
      this.onTick(this.cnt)
    }
    this.cnt++
  }

}

export default Timer;
