let instance = null

class Player {

  static instance() {
    if (instance) {
      return instance
    } else {
      instance = new Player()
      return instance
    }
  }

  constructor() {
    this.playlist = []
    this.loadedCallback = null
    this.playheadCallback = null
  }

  // load all files and callback the progress
  loadFiles(files) {
    this.players = files.map((filename) => {
      var audioElement = new Audio(filename + ".mp3");
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        audioElement.autoplay = true //???
      }
      // every time a player has loaded its data check if all of them are done
      audioElement.addEventListener('canplaythrough', (el) => {
        var loaded = this.players.filter((player) => {
          return player.readyState >= 2;
        })
        if (this.loadedCallback) {
          //console.log(el.target)
          this.loadedCallback(loaded.length)
        }
      })
      // receive playhead info from all players
      audioElement.addEventListener('timeupdate', (el) => {
        this.onPlayerUpdate(el.target)
      })
      // get notified when a player is done playing
      audioElement.addEventListener('ended', (el) => {
        this.onPlayerDone(el.target)
      })
      // get notified when a player is paused, this does not work in all browsers
      audioElement.addEventListener('paused', (el) => {
        this.onPlayerAborted(el.target)
      })
      return audioElement;
    })
  }

  // play one or more files
  playFiles(files) {
    this.playlist = [].concat(files)
    console.log("playlist", this.playlist)
    this.stop()
    this.playNext()
  }

  // stop all playing
  stop() {
    var playing = this.players.filter((player) => {
      return player.paused === false
    })
    playing.forEach((player, i) => {
      console.log('pausing', playing)
      player.pause()
    })
  }

  // event handler
  onPlayerUpdate(player) {
    if (this.playheadCallback) {
      this.playheadCallback(player.getAttribute("src").split(".")[0], player.currentTime)
    }
    if (player.paused === true) {
      this.onPlayerAborted(player)
    }
  }

  // event handler
  onPlayerAborted() {
    if (this.playheadCallback) {
      this.playheadCallback(null, null)
    }
  }

  // event handler
  onPlayerDone(player) {
    this.playNext()
  }

  // internal function
  playFile(filename) {
    var player = this.players.filter((player) => {
      return player.getAttribute("src").split(".")[0] === filename
    })[0]
    if (player) {
      player.currentTime = 0
      player.play();
    }
  }

  // internal function
  playNext() {
    var filename = this.playlist.shift()
    if (filename) {
      this.playFile(filename)
    }
  }

}

export default Player;
