import React from 'react'
import Pad16 from './Pad16.js'
import './LaunchPad.css'

class Mapping {

  // from original layout position
  static map(r, c) {
    var num = r * 16 + c
    var unmapped = Mapping.mapping[num] //int
    var pad = null
    var row = null
    var col = null
    if(unmapped) {
      pad = Math.floor(unmapped / 16)
      row = Math.floor((unmapped - pad * 16) / 4)
      col = unmapped - pad * 16 - row * 4
    }
    return {
      pad: pad,
      row: row,
      col: col
    }
  }

  // to original layout position
  static unmap(p, r, c) {
    var num = p * 16 + r * 4 + c
    var mapped = Mapping.mapping.indexOf(num)
    var row  = null
    var col = null
    if(mapped !== -1) {
      row = Math.floor(mapped / 16)
      col = mapped - row * 16
    }
    return {
      row: row,
      col: col
    }
  }

}

Mapping.mapping = [
  [0, 4, 1, 8, 5, 2, 12, 9, 6, 3, 13, 10, 7, 14, 11, 15],
  [16, 20, 17, 24, 21, 18, 28, 25, 22, 19, 29, 26, 23, 30, 27, 31],
  [32, 36, 33, 40, 37, 34, 44, 41, 38, 35, 45, 42, 39, 46, 43, 47],
  [48, 52, 49, 56, 53, 50, 60, 57, 54, 51, 61, 58, 55, 62, 59, 63]
].reduce((a, b) => a.concat(b), [])

class LaunchPad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      helpButton: "",
      rowButtons: ["", "", "", ""]
    };
    this.pads = [...Array(4)].map((_, j) => {
      return React.createRef()
    });
  }

  componentDidMount() {
    document.addEventListener('fullscreenchange', this.onFullScreenChange.bind(this), false);
    document.addEventListener('mozfullscreenchange', this.onFullScreenChange.bind(this), false);
    document.addEventListener('MSFullscreenChange', this.onFullScreenChange.bind(this), false);
    document.addEventListener('webkitfullscreenchange', this.onFullScreenChange.bind(this), false);
  }

  componentWillUnmount() {
  }

  // get the array of colors for each light/button, last one is row button
  setLights(lights) {
    // unmap the row position to the 4x4 grid position
    var mappedPixels = [...Array(4)].map((_, p) => {
      return [...Array(4)].map((_, r) => {
        return [...Array(4)].map((_, c) => {
          const {row, col} = Mapping.unmap(p, r, c)
          return (lights[row] || [])[col]
        })
      })
    })
    // set the pixel buttons
    mappedPixels.forEach((padPixels, i) => {
      this.pads[i].current.setPixels(padPixels)
    })
    // get the status for the rounds buttons (the last ones in the row)
    var roundButtons =  [...Array(4)].map((_, p) => {
      return (lights[p] || []).slice(-1)[0]
    })
    // get the status for the help button
    var helpButton = lights.slice(-1)[0]

    this.setState((state, props) => {
      return {
        helpButton: helpButton,
        rowButtons: roundButtons
      }
    })
  }

  onClick(pad, r, c) {
    var {row, col} = Mapping.unmap(pad, r, c)
    if(row != null && col !== null) {
      this.props.onClick(row, col)
    }
  }

  onDoubleClick(pad, r, c) {
    var {row, col} = Mapping.unmap(pad, r, c)
    if(row != null && col !== null) {
      this.props.onDoubleClick(row, col)
    }
  }

  onHold(pad, r, c) {
    var {row, col} = Mapping.unmap(pad, r, c)
    if(row != null && col !== null) {
      this.props.onHold(row, col)
    }
  }

  onHoldRelease(pad, r, c) {
    var {row, col} = Mapping.unmap(pad, r, c)
    if(row != null && col !== null) {
      this.props.onHoldRelease(row, col)
    }
  }

  onPadButton(evt) {
    var id = Math.floor(evt.target.dataset.element)
    if(this.timer) {
      // cancel upcoming (delayed) single click
      clearTimeout(this.timer)
      this.timer = null
      this.props.onPadButton(id)
    } else {
      // set timer with offset for single click
      this.timer = setTimeout(() => {
        this.timer = null
        this.props.onPadButton(id)
      }, 250);
    }
    evt.preventDefault()
  }

  onFullScreenButton(evt) {
    var el = document.getElementsByClassName('App-middle')[0];
    if (el.requestFullscreen) {
      el.requestFullscreen()
    } else if (el.mozRequestFullScreen) { /* Firefox */
      el.mozRequestFullScreen()
    } else if (el.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      el.webkitRequestFullscreen()
    } else if (el.msRequestFullscreen) { /* IE/Edge */
      el.msRequestFullscreen()
    }
    evt.preventDefault()
  }

  onHelpButton(evt) {
    this.props.onHelp()
    evt.preventDefault()
  }

  onFullScreenChange(evt) {
    var fullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
    this.setState((state, props) => {
      return {fullScreen: fullScreen}
    })
  }

  render() {
    var elements = [...Array(4)].map((_, i) => {
      return (
        <div key={i} className={`LaunchPad-element LaunchPad-element-${i}`}>
          <div className="LaunchPad-element-col LaunchPad-element-col-0">
            <Pad16
              id={i}
              ref={this.pads[i]}
              onClick={this.onClick.bind(this)}
              onDoubleClick={this.onDoubleClick.bind(this)}
              onHold={this.onHold.bind(this)}
              onHoldRelease={this.onHoldRelease.bind(this)}
            />
          </div>
          <div className="LaunchPad-element-col LaunchPad-element-col-1">
            <div className="LaunchPad-element-image">
              <div
                className={`LaunchPad-element-button ${this.state.rowButtons[i]}`}
                data-element={i}
                onClick={this.onPadButton.bind(this)} >
              </div>
            </div>
          </div>
        </div>
      )
    });
    var fullScreenButton = this.state.fullScreen ? null : <div className="LaunchPad-fullscreen" onClick={this.onFullScreenButton.bind(this)}></div>
    return (
      <div className="LaunchPad-container">
        {elements}
        <div className={`LaunchPad-help ${this.state.helpButton}`} onClick={this.onHelpButton.bind(this)}>help</div>
        {fullScreenButton}
      </div>
    );
  }
}

export default LaunchPad;
