import React from 'react'
import './Pad16.css'

class Pad16 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pixels : new Array(16)
    }
  }

  setPixels(pixels) {
    this.setState((state, props) => {
      return {pixels: pixels}
    })
  }

  onMouseDown(e) {
    var pad = parseInt(this.props.id)
    var row = parseInt(e.target.dataset.row)
    var col = parseInt(e.target.dataset.col)

    //
    this.holdTimer = setTimeout(() => {
      this.holdTimer = null
      this.props.onHold(pad, row, col)
    }, 500);

    e.preventDefault();
  }

  // implemented own double click handler
  // using the React double click would make us have to distinguish single and double click using a timer
  // the problem is that we would have to know the system its double click time threshold and we don't
  // this way we control timing ourselves
  onMouseUp(e) {
    var pad = parseInt(this.props.id)
    var row = parseInt(e.target.dataset.row)
    var col = parseInt(e.target.dataset.col)
    // timer still running, it's a double click

    // if hold timer is gone, the button was hold and cannot be clicked or double clicked
    if(this.holdTimer == null) {
      // clear the timer for double clicks
      clearTimeout(this.timer)
      this.props.onHoldRelease(pad, row, col)
    } else if(this.timer) {
      clearTimeout(this.holdTimer)
      this.holdTimer = null
      // cancel upcoming (delayed) single click
      clearTimeout(this.timer)
      this.timer = null
      this.props.onDoubleClick(pad, row, col)
    } else {
      //
      clearTimeout(this.holdTimer)
      this.holdTimer = null
      // set timer with offset for single click
      this.timer = setTimeout(() => {
        this.timer = null
        this.props.onClick(pad, row, col)
      }, 250);
    }
    //
    e.preventDefault();
  }

  render() {

    var rows = this.state.pixels.map((row, y) => {
      var buttons = row.map((pixel, x) => {
        var color = pixel
        if(Array.isArray(pixel)) {
          color = `hsl(${pixel[0]},${pixel[1]}%,${pixel[2]}%)`
        }
        return (
          <div
            className={`button ${color}`}
            key={x}
            data-row={y}
            data-col={x}
            onTouchStart={this.onMouseDown.bind(this)}
            onTouchEnd={this.onMouseUp.bind(this)}
            onMouseUp={this.onMouseUp.bind(this)}
            onMouseDown={this.onMouseDown.bind(this)}>
          </div>
        )
      })
      return <div key={y} className="row">{buttons}</div>
    })

    return (
      <div className="Pad16-pad center">
        {rows}
      </div>
    );
  }
}

export default Pad16;
