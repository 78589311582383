import {Button} from './Button.js'
import Config from './Config.js'

// todo initial row  can have persons in different order
// init butons based on config

class Row {

  constructor(numCols, id) {
    this.row = id // maps to topic
    // add colored buttons
    this.buttons = [...Array(numCols)].map((_, i) => {
      var b = new Button()
      // set references
      b.row = this
      b.col = i
      // map person / fragment
      var buttonConfig = (Config.getMapping()[id] || [])[i]
      if(buttonConfig) {
        b.active = false // Math.random() < 0.3
        b.person = buttonConfig.person
        b.fragment = buttonConfig.fragment
      }
      return b
    })
    // add intro button
    var rowButton = new Button()
    rowButton.row = this
    this.buttons.push(rowButton)
  }

  isPlaying() {
    return this.buttons.filter((b) => b.playing === true).length > 0
  }

  isAnimating() {
    return this.buttons.filter((b) => b.animating === true).length > 0
  }

  getPlaylist() {
    return this.buttons.filter((b) => b.isOn()).map((b) => b.getFilename())
  }

}

export default Row
