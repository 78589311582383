import React from 'react'
import './LogoBar.css'

const LogoBar = props =>
  <div>
    <div className="LogoBar-logos">
      <div className="LogoBar-logo LogoBar-logo-ImagineIC"></div>
      <div className="LogoBar-logo LogoBar-logo-AFK"></div>
      <div className="LogoBar-logo LogoBar-logo-Waag"></div>
    </div>
    <div className="LogoBar-credits">
      Het project Digitaal Dynamisch Documenteren is een initiatief van Waag en Imagine IC. Het project wordt mogelijk gemaakt met steun van het Amsterdams Fonds voor de Kunst.
    </div>
  </div>

export default LogoBar
