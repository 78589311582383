import React from 'react'
import './App.css'
import LaunchPad from './LaunchPad.js'
import Player from './Player.js'
import Controller from './Controller.js'
import Timer from './Timer.js'
import IntroText from './IntroText.js'
import LogoBar from './LogoBar.js'

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded : 0
    }
    this.launchPad = React.createRef();
  }

  componentDidMount() {
    Timer.instance().onTick = this.onTick.bind(this)
    Player.instance().loadedCallback = this.onDoneLoading.bind(this);
    Controller.instance().init([16, 4]) //x, y dimensions
    // bind things together
    Player.instance().playheadCallback = ((filename, position) => {
      Controller.instance().setButtonPlaying(filename, position)
    })
    Controller.instance().onPreview = ((filename) => {
      Player.instance().playFiles(filename)
    })
    Controller.instance().onPlayPlaylist = ((list) => {
      Player.instance().playFiles(list)
    })
    Controller.instance().onStop= ((filename) => {
      Player.instance().stop()
    })
  }

  onTick(cnt) {
    var display = Controller.instance().getDisplay()
    if(this.launchPad && this.launchPad.current) {
      this.launchPad.current.setLights(display)
    }
    //console.log(cnt)
    if(cnt === 0)  {
      var filenames = Controller.instance().listFilenames()
      Player.instance().loadFiles(filenames)
    }
  }

  componentWillUnmount() {

  }

  onPlayerUpdate(filename, position) {
    console.log("playing:", filename, "at:", position)
  }

  onDoneLoading(cnt) {
    this.setState((state, props) => {
      return {
        loaded: cnt
      }
    })
    console.log("loaded: ", cnt)
  }

  onClick(row, col) {
    Controller.instance().onClick(row, col)
  }

  onDoubleClick(row, col) {
    Controller.instance().onClick(row, col)
    //Controller.instance().onDoubleClick(row, col)
  }

  onHold(row, col) {
    Controller.instance().onClick(row, col)
    //Controller.instance().onDoubleClick(row, col)
  }

  onHoldRelease(row, col) {
    Controller.instance().onClick(row, col)
  }

  onPadButton(num) {
    Controller.instance().onRowButton(num)
  }

  onHelp() {
    Controller.instance().onHelpButton()
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
        </header>
        <section className="App-upper">
          <div className="App-info">
            <IntroText/>
          </div>
        </section>
        <section className="App-middle">
          <LaunchPad ref={this.launchPad}
            onPadButton={this.onPadButton.bind(this)}
            onClick={this.onClick.bind(this)}
            onDoubleClick={this.onDoubleClick.bind(this)}
            onHold={this.onHold.bind(this)}
            onHoldRelease={this.onHoldRelease.bind(this)}
            onHelp={this.onHelp.bind(this)}
          />
        </section>
        <section className="App-lower">
          <div className="App-ready" onClick={() => {
            window.open("https://waag.typeform.com/to/dKkqN4", "_blank")
          }}>
          </div>
          <div className="App-ready-text">
            <strong>Klaar</strong><br/>
            Deel mijn selectie!
          </div>
          <div className="App-info">
          </div>
          <LogoBar/>
        </section>
      </div>
    );
  }
}



export default App;
